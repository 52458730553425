<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <!-- <div class="card">
          <tracker></tracker>
        </div> -->
        <div class="card mt-4 p-3 pt-0">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                style="width: 100%"
                @input="searchForOrder"
                placeholder="Search By Order #, PO#, Client, Customer, Store"
              >
              </soft-input>
            </div>

            <div class="table-wrapper">
              <table id="order-list" class="table">
                <thead class="thead-light">
                  <tr>
                    <th>
                      <div>
                        <input
                          v-model="handleSelectedAll"
                          type="checkbox"
                          name=""
                          id=""
                        />
                      </div>
                      <div>Controls</div>
                    </th>
                    <th>Date/Time</th>
                    <th>Order #</th>
                    <th>PO #</th>
                    <th>Item Type</th>
                    <th>SKU</th>
                    <th>Store's Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="order in queriedData"
                    :key="order.id"
                    class="table-row"
                  >
                    <td
                      style="padding-left: 33px; padding-right: 33px"
                      class="align-items-center"
                    >
                      <soft-checkbox
                        class="font-weight-light"
                        :id="order.id"
                        :checked="selectedOrder[order.id]"
                        @input="handleSelectedOrder(order)"
                      />
                    </td>

                    <td class="font-weight-bold">
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ format_date_time(order.createdAt) }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.orderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.purchaseOrderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.jewelryType }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.sku }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{
                            format_price(order.priceBreakdown.storeTotalCost)
                          }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="pagination-wrapper">
              <div class="page-dropdown">
                <label for="orders">Per page: &nbsp; </label>
                <select v-model="pagination.perPage" name="orders">
                  <option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>

              <pagination
                :value="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :from="from"
                :to="to"
                @input="handlePagination"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  components: {
    SoftCheckbox,
    Pagination,
  },
  props: {
    tableData: {
      type: Array,
      default: [],
    },
  },
  emits: ["handle-selected-order"],
  data() {
    return {
      selectedOrder: {},
      selectedOrders: [],
      searchedData: [],
      handleSelectedAll: false,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  watch: {
    handleSelectedAll(toggle) {
      if (toggle) {
        this.tableData.forEach((order) => {
          this.selectedOrder[order.id] = true;
          this.selectedOrders.push(order);
        });
      } else {
        this.selectedOrders = [];
        this.selectedOrder = {};
      }

      this.$emit("handle-selected-order", this.selectedOrders);
    },
  },
  methods: {
    searchForOrder(e) {
      if (!e.target.value) {
        this.tableData = this.order;
      }
      let input = e.target.value.toLowerCase();
      const validateOrder = (order) => {
        if (
          order.orderNumber.toLowerCase().includes(input) ||
          order.sku.toLowerCase().includes(input) ||
          order.purchaseOrderNumber.toLowerCase().includes(input) ||
          order.consumer.email.toLowerCase().includes(input) ||
          `${order.consumer.name} ${order.consumer.lastName}`
            .toLowerCase()
            .includes(input) ||
          // order.storeId.includes(input) ||
          order.client.company.toLowerCase().includes(input)
        ) {
          return true;
        } else return false;
      };
      this.tableData = this.orders.filter((order) => validateOrder(order));
    },
    handleSelectedOrder(order) {
      if (!this.selectedOrder[order.id]) {
        this.selectedOrders.unshift(order);
        this.selectedOrder[order.id] = true;
      } else {
        this.selectedOrders = this.selectedOrders.filter(
          (o) => o.id !== order.id
        );
        delete this.selectedOrder[order.id];
      }

      this.$emit("handle-selected-order", this.selectedOrders);
    },
  },
};
</script>

<style>
</style>