<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div v-if="isSuperAdmin">
        <soft-button
          data-bs-toggle="modal"
          data-bs-target="#New"
          color="success"
          variant="gradient"
        >
          New Special Order
          <span class="btn-inner--icon"> <i class="ni ni-diamond"></i> </span
        ></soft-button>
      </div>
      <div class="d-flex">
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="dark"
          variant="outline"
          data-type="pdf"
          @click="exportReport"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-archive-2"></i>
          </span>
          <span class="btn-inner--text">Export Report</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <!-- <div class="card">
          <tracker></tracker>
        </div> -->
        <div class="card mt-4 p-3 pt-0">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                style="width: 100%"
                @input="searchForOrder"
                placeholder="Search By Order #, PO#, Client, Customer, Store"
              >
              </soft-input>
              <div
                v-click-outside="() => (toggleColorDropdown = false)"
                class="color-key-container"
              >
                <div class="">
                  <div class="" id="headingOne">
                    <h5 class="mb-0">
                      <a
                        data-bs-toggle="collapse"
                        href="#color-key-dropdown"
                        aria-controls="color-key-dropdown"
                        aria-expanded="false"
                        class="btn btn-link"
                        v-bind="$attrs"
                        type="button"
                        @click="toggleColorDropdown = !toggleColorDropdown"
                      >
                        Color Key
                      </a>
                    </h5>
                  </div>

                  <div
                    id="color-key-dropdown"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div class="color-key-dropdown">
                      <div
                        class="color-key-list"
                        v-for="(color, status) in statusColors"
                        :key="status"
                      >
                        <div
                          @click="filterByColor"
                          :style="{
                            backgroundColor: color,
                            cursor: 'pointer',
                            color: status.includes('Delayed')
                              ? 'white'
                              : 'black',
                          }"
                          class="color-status"
                        >
                          {{ status }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="light">
              <div class="filters">
                <div v-if="isSuperAdmin || isEmployee" class="filter">
                  <p class="filter-label">Clients</p>
                  <v-select
                    :appendToBody="true"
                    :deselectFromDropdown="true"
                    v-model="filterBy.client"
                    :options="clients"
                    label="company"
                    :reduce="(t) => t.id"
                    :closeOnSelect="true"
                  >
                  </v-select>
                </div>

                <div v-if="isSuperAdmin || isEmployee" class="filter">
                  <p class="filter-label">Sales Rep</p>
                  <v-select
                    :appendToBody="true"
                    :deselectFromDropdown="true"
                    v-model="filterBy.rep"
                    :options="salesReps"
                    :reduce="(s) => s.id"
                    :closeOnSelect="true"
                    label="firstName"
                  >
                  </v-select>
                </div>

                <div class="filter">
                  <p class="filter-label">Start From:</p>
                  <input
                    type="date"
                    @change="updateTimestamp($event, 'starDate')"
                    class="datetime-input"
                    v-model="filterBy.startDate"
                  />
                </div>
                <div class="filter">
                  <p class="filter-label">End At:</p>
                  <input
                    type="date"
                    @change="updateTimestamp($event, 'endDate')"
                    class="datetime-input"
                    v-model="filterBy.endDate"
                  />
                </div>

                <div class="filter filter-btn">
                  <soft-button
                    type="button"
                    color="primary"
                    variant="gradient"
                    class="ms-auto mb-0 js-btn-next"
                    @click="handleFilter"
                  >
                    Filter
                  </soft-button>
                  <soft-button
                    type="button"
                    color="warning"
                    variant="gradient"
                    class="ms-auto mb-0 js-btn-next"
                    @click="clearFilters"
                  >
                    Clear
                  </soft-button>
                </div>
              </div>
            </div>

            <div class="table-wrapper">
              <table id="order-list" class="table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Date/Time</th>
                    <th>Special Order ID</th>
                    <th>Sales Rep</th>
                    <th>Client</th>
                    <!-- <th>Store ID</th> -->
                    <th>Item Type</th>
                    <th>Style</th>
                    <th>Quality</th>
                    <th>Carat Weight</th>
                    <th>Shape</th>
                    <th>Metal</th>
                    <th>Quoted Price</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="order in queriedData"
                    :key="order.id"
                    @click="handleModalInitilization('Details', order)"
                    class="table-row"
                    :style="{
                      backgroundColor: statusColors[order.status],
                      color: order.status.includes(
                        'Proposal Rejected By Client'
                      )
                        ? 'white'
                        : 'black',
                      'font-weight': 900,
                    }"
                  >
                    <td class="font-weight-bold">
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ format_date_time(order.createdAt) }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.orderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{
                            `${order.salesRep.firstName} ${order.salesRep.lastName}`
                          }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.client.company }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.jewelryType }}
                        </p>
                      </div>
                    </td>

                    <td>
                      <div class="align-items-center">
                        <!-- <soft-checkbox /> -->
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.style }}
                        </p>
                      </div>
                    </td>

                    <td>
                      <p class="text-xs font-weight-bold ms-2 mb-0">
                        {{ `${order.origin} - ${order.quality}` }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold ms-2 mb-0">
                        {{ order.caratWeight }} ct t.w
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold ms-2 mb-0">
                        {{ order.shape }}
                      </p>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold ms-2 mb-0">
                        {{ order.metal.name }}
                      </p>
                    </td>
                    <td>
                      <div v-if="isClient">
                        <p
                          v-if="order.approximateFinalRetailPrice"
                          style="color: red"
                          class="text-xs font-weight-bold ms-2 mb-0"
                        >
                          {{ format_price(order.approximateFinalRetailPrice) }}
                        </p>

                        <p v-else class="text-xs font-weight-bold ms-2 mb-0">
                          TBD
                        </p>
                      </div>
                      <div v-else>
                        <!-- <soft-checkbox /> -->
                        <p
                          v-if="order.quoutedPrice"
                          style="color: red"
                          class="text-xs font-weight-bold ms-2 mb-0"
                        >
                          {{ format_price(order.quoutedPrice) }}
                        </p>

                        <p v-else class="text-xs font-weight-bold ms-2 mb-0">
                          TBD
                        </p>
                      </div>
                    </td>
                    <td>
                      <p class="text-xs font-weight-bold ms-2 mb-0">
                        {{ order.status }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="pagination-wrapper">
              <div class="page-dropdown">
                <label for="special_orders">Per page: &nbsp; </label>
                <select v-model="pagination.perPage" name="special_orders">
                  <option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>

              <pagination
                :value="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :from="from"
                :to="to"
                @input="handlePagination"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    v-if="anOrderHasBeenSelected"
    @close="handleModalClose('Details')"
    id="Details"
    :styleFormat="{ width: '1200px', marginLeft: '-20.75rem' }"
  >
    <template v-slot:title>
      <h2>
        Order Details -
        <span :style="{ color: statusColors[selectedOrder.status] }">{{
          selectedOrder.status
        }}</span>
      </h2>
    </template>
    <template v-slot:body>
      <div class="order-details-wrapper">
        <OrderDetails @handle-input="handleOrderInput" :order="selectedOrder" />
      </div>
    </template>
    <template v-slot:footer>
      <button
        v-if="showSaveChanges && selectedOrder.proposalAccepted"
        @click="saveChanges"
        type="button"
        class="btn btn-primary"
      >
        Save changes
      </button>
      <button
        v-if="
          selectedOrder.status == 'Pending Luna\'s Approval' ||
          selectedOrder.status == 'Pending Client\'s Approval' ||
          (selectedOrder.status == 'Proposal Rejected By Client' &&
            isSuperAdmin)
        "
        @click="handleProposal(true)"
        type="button"
        class="btn btn-success"
      >
        Accept Proposal
      </button>
      <button
        v-if="
          selectedOrder.status == 'Pending Luna\'s Approval' ||
          selectedOrder.status == 'Pending Client\'s Approval'
        "
        @click="handleProposal(false)"
        type="button"
        class="btn btn-danger"
      >
        Reject Proposal
      </button>
    </template>
  </modal>

  <modal
    @close="closeModal('New')"
    id="New"
    :styleFormat="{ width: '1200px', marginLeft: '-20.75rem' }"
  >
    <template v-slot:title>
      <h2>New Order</h2>
    </template>
    <template v-slot:body>
      <div class="order-details-wrapper">
        <NewOrder :clients="clients"></NewOrder>
      </div>
    </template>
    <template v-if="showSaveChanges" v-slot:footer>
      <button @click="saveChanges" type="button" class="btn btn-primary">
        Save changes
      </button>
    </template>
  </modal>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import Pagination from "@/components/Pagination.vue";
import OrderDetails from "@/views/ecommerce/SpecialOrders/SpecialOrderDetails.vue";
import NewOrder from "./NewSpecialOrder.vue";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ExcelJS from "exceljs";
import { DateTime } from "luxon";

import {
  collection,
  doc,
  db,
  orderBy,
  query,
  updateDoc,
  onSnapshot,
  where,
  endAt,
  startAt,
  limit,
  getDoc,
  setDoc
} from "@/config/firebaseInit.js";
import { mapState } from "vuex";

export default {
  name: "OrderList",
  components: {
    SoftButton,
    SoftAvatar,
    SoftCheckbox,
    SoftInput,
    Modal,
    Pagination,
    OrderDetails,
    NewOrder,
  },
  data() {
    return {
      anOrderHasBeenSelected: false,
      toggleColorDropdown: false,
      openNewOrderModal: false,
      initReportExport: false,
      statusColors: {
        "Pending Quote": "rgb(0, 243, 255)",
        "Pending Client's Approval": "rgb(0, 203, 255)",
        "Pending Luna's Approval": "rgb(255, 239, 0)",
        "Proposal Rejected By Client": "rgba(255, 64, 73, 0.61)",
        "Proposal Accepted - Waiting For SKU": "rgba(59, 217, 76, 0.61)",
      },
      metals: {
        wg: "White Gold",
        yg: "Yellow Gold",
        rg: "Rose Gold",
        plt: "Platinum",
      },
      shapeType: {
        earth: "Natural Stone",
        lab: "Lab Made",
      },
      ordersRef: {},
      tableData: [],
      searchedData: [],
      orders: [],
      salesReps: [],
      clients: [],
      showModal: false,
      showSaveChanges: false,
      selectedOrder: {},
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
      currentSort: "name",
      currentSortOrder: "asc",
      filterBy: {
        client: "",
        status: "",
        rep: "",
        startDate: "",
        endDate: "",
      },
    };
  },
  computed: {
    ...mapState("authModule", {
      currentUser: (state) => {
        if (state.admin.id) {
          return state.admin;
        } else {
          return JSON.parse(window.localStorage.getItem("admin_data"));
        }
      },
      isSuperAdmin: (state) => {
        return state.isSuperAdmin;
      },
      isClient: (state) => {
        return state.isClient;
      },
      isStore: (state) => {
        return state.isStore;
      },
      isSalesRep: (state) => {
        return state.isSalesRep;
      },

      isEmployee: (state) => {
        return state.isEmployee;
      },
    }),
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  watch: {
    toggleColorDropdown(val) {
      let myCollapse = document.getElementById("color-key-dropdown");
      if (!val) {
        setTimeout(() => {
          myCollapse.classList.remove("show");
          let bsCollapse = new bootstrap.Collapse(myCollapse, {
            toggle: false,
          });
        }, 500);
      } else {
        let bsCollapse = new bootstrap.Collapse(myCollapse, {
          toggle: true,
        });
      }
    },
  },
  created() {
    this.fetchData("start");

    if (this.isSuperAdmin || this.isEmployee) {
      this.getAllClients();
      this.getAllSalesReps();
    }
  },
  methods: {
    async saveChanges() {
      await setDoc(doc(db, "special_orders", this.selectedOrder.id), this.selectedOrder).then(
        () => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `Special Order # ${this.selectedOrder.id} has been updated`,
            type: "success",
          });

          setTimeout(() => {
            this.closeModal("Details");
          }, 500);
        }
      );
    },
    closeModal(id) {
      const modalElement = document.getElementById(id);
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.hide();
      bootstrapModal.dispose();
    },
    openModal(id) {
      const modalElement = document.getElementById(id);
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.show();
    },
    handleModalInitilization(modalId, order) {
      this.anOrderHasBeenSelected = true;
      this.selectedOrder = {};
      this.selectedOrder = order;

      setTimeout(() => {
        this.openModal(modalId);
      }, 500);
    },
    handleModalClose(modalId) {
      this.anOrderHasBeenSelected = false;
      this.showSaveChanges = false
      setTimeout(() => {
        this.closeModal(modalId);
        this.selectedOrder = {};
      }, 500);
    },
    updateTimestamp(event, key) {
      this.filterBy[key] = event.target.value;
    },
    async handleFilter() {
      let timeFilter = false;

      let orderQuery = collection(db, "special_orders");

      if (
        !this.filterBy.client &&
        !this.filterBy.status &&
        !this.filterBy.rep &&
        !this.filterBy.startDate &&
        !this.filterBy.endDate
      ) {
        // this.resetTable();

        return false;
      }

      try {
        if (this.filterBy.startDate && this.filterBy.endDate) {
          timeFilter = true;
          let startDateObj =
            typeof this.filterBy.startDate == "object"
              ? DateTime.fromJSDate(this.filterBy.startDate)
              : DateTime.fromISO(this.filterBy.startDate);

          let endDateObj =
            typeof this.filterBy.endDate == "object"
              ? DateTime.fromJSDate(this.filterBy.endDate)
              : DateTime.fromISO(this.filterBy.endDate);

          let startDate = startDateObj
            .setZone("America/New_York")
            .startOf("day")
            .toMillis();

          let endDate = endDateObj
            .setZone("America/New_York")
            .endOf("day")
            .toMillis();

          orderQuery = query(
            orderQuery,
            orderBy("createdAt", "desc"),
            startAt(endDate),
            endAt(startDate)
          );
        }

        for (const key in this.filterBy) {
          if (Object.hasOwnProperty.call(this.filterBy, key)) {
            if (key != "startDate" && key != "endDate") {
              const element = this.filterBy[key];
              if (element) {
                if (key == "client") {
                  orderQuery = query(
                    orderQuery,
                    where("client.id", "==", element)
                  );
                }

                if (key == "status") {
                  orderQuery = query(
                    orderQuery,
                    where("status", "==", element)
                  );
                }

                if (key == "rep") {
                  orderQuery = query(
                    orderQuery,
                    where("salesRep.id", "==", element)
                  );
                }
              }
            }
          }
        }

        if (timeFilter) {
          await onSnapshot(orderQuery, (querySnapshot) => {
            if (!querySnapshot.docs.length) {
              return this.$swal("Empty Search Records");
            }
            this.searchedData = querySnapshot.docs.map((d) =>
              Object.assign({ id: d.id }, d.data())
            );
          });
        } else {
          await onSnapshot(orderQuery, (querySnapshot) => {
            if (!querySnapshot.docs.length) {
              return this.$swal("Empty Search Records");
            }
            this.searchedData = querySnapshot.docs.map((d) =>
              Object.assign({ id: d.id }, d.data())
            );
          });
        }
      } catch (error) {
        console.debug(error);

        return this.$swal({
          icon: "error",
          title: "Internal Issue",
          text: "Oops there seems to be an issue please try again or contact support at info@mylunacollection.com",
          type: "error",
        });
      }
    },
    clearFilters() {
      for (const key in this.filterBy) {
        if (key === "client" || key === "status") {
          this.filterBy[key] = "";
        } else if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
        } else {
          this.filterBy[key] = "";
        }
      }
      this.searchVal = null;
      this.resetTable();
    },
    async printOrderConfirmation(order) {
      this.selectedOrder = order;
      setTimeout(async () => {
        await this.fetchProductImage(order);
        this.exportToPDF();
      }, 500);
    },

    async fetchProductImage(order) {
      try {
        const image = order.images.filter((i) => i.type.includes("image"));
        const displayedImage = image[0] || null;
        this.selectedOrder.productImageDataUrl =
          await this.getBase64ImageFromUrl(displayedImage.url);
      } catch (error) {
        console.debug("Error fetching product image:", error);
      }
    },
    getBase64ImageFromUrl(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          var dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        };
        img.onerror = function () {
          reject("Error loading image");
        };
        img.src = url;
      });
    },
    async exportToPDF() {
      const order_container_ref = this.$refs.order_receipt_container;
      const pdf = new jsPDF();
      const content = order_container_ref.$refs.contentToExport; // Replace with your element's ref or selector
      const canvas = await html2canvas(content);
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 5, 10, 200, 200);
      pdf.save(`${this.selectedOrder.purchaseOrderNumber}.pdf`);
      pdf.autoPrint();
      pdf.output("dataurlnewwindow");
    },

    exportReport() {
      this.initReportExport = true;
      setTimeout(async () => {
        const report_container_ref = this.$refs.report_container;
        const reportContent = report_container_ref.$refs.reportContent;
        const canvas = await html2canvas(reportContent);
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("report.pdf");
        this.initReportExport = false;
      });
    },
    async handleProposal(accepted) {
      if (this.isClient) this.handleClientProposal(accepted);
      else this.handleLunaProposal(accepted);
    },
    async handleClientProposal(accepted) {
      let status = !accepted
        ? "Proposal Rejected By Client"
        : "Proposal Accepted - Waiting For SKU";
      await updateDoc(doc(db, "special_orders", this.selectedOrder.id), {
        proposalAccepted: accepted,
        status,
      })
        .then(() => {
          //Production Payload
          // const notification = {
          //   fullName: "Luna Administrators",
          //   type: "Special Order",
          //   to: ["sam@ttcusadiamond.com"],
          //   cc: ["info@mylunacollection.com"],
          //   message: `There's an update on Special Order ${
          //     this.selectedOrder.id
          //   }, Status: ${
          //     !accepted
          // ? "Proposal Rejected By Client"
          // : "Proposal Accepted,  Once SKU and a Product Image has been added, the Special Order will be converted to a sales order.."
          //   }`,
          // };

          //Staging Payload
          const notification = {
            fullName: "Luna Administrators",
            type: "Special Order",
            to: ["kelvin@diamondservicesusa.com"],
            cc: ["info@mylunacollection.com"],
            message: `There's an update on Special Order ${
              this.selectedOrder.id
            }, Status: ${
              !accepted
                ? "Proposal Rejected By Client"
                : "Proposal Accepted,  Once SKU and a Product Image has been added, the Special Order will be converted to a sales order.."
            }`,
          };

          return this.sendEmailNoteNotificationToUsers(notification);
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `Special Order # ${this.selectedOrder.id} has been updated`,
            type: "success",
          });
        });
    },
    async handleLunaProposal(accepted) {
      let status = !accepted
        ? "Proposal Rejected"
        : "Pending Client's Approval";
      await updateDoc(doc(db, "special_orders", this.selectedOrder.id), {
        status,
        approximateFinalRetailPrice:
          this.selectedOrder.approximateFinalRetailPrice,
      })
        .then(async () => {
          let client = await getDoc(
            doc(collection(db, "clients"), this.selectedOrder.client.id)
          );
          client = client.data();

          //Production Payload
          // const notification = {
          //   fullName: "Luna Administrators",
          //   type: "Special Order",
          //   to: accepted ? [client.email] : ["sam@ttcusadiamond.com"],
          //   cc: [
          //     "info@mylunacollection.com",
          //     "jiger@trezzajewels.com",
          //     "nihar@trezzajewels.com",
          //     "csd1@trezzajewels.com",
          //     "prashant@trezzajewels.com",
          //   ],
          //   message: `There's an update on Special Order ${
          //     this.selectedOrder.id
          //   }, Status: ${
          //     !accepted
          //       ? "Proposal Rejected"
          //       : `Proposal Accepted by Luna's Finance Team. Dear client please review Luna's Proposal for Special Order ${this.selectedOrder.id}.`
          //   }`,
          // };

          //Staging Payload
          const notification = {
            fullName: "Luna Administrators",
            type: "Special Order",
            to: ["kelvin@diamondservicesusa.com"],
            cc: ["info@mylunacollection.com"],
            message: `There's an update on Special Order ${
              this.selectedOrder.id
            }, Status: ${
              !accepted
                ? "Proposal Rejected"
                : `Proposal Accepted by Luna's Finance Team. Dear client please review Luna's Proposal for Special Order ${this.selectedOrder.id}.`
            }`,
          };

          return this.sendEmailNoteNotificationToUsers(notification);
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `Special Order # ${this.selectedOrder.id} has been updated`,
            type: "success",
          });
        });
    },
    handleOrderInput(e, key, subKey) {
      this.showSaveChanges = true;
      if (subKey) this.selectedOrder[key][subKey] = e.target.value;
      else this.selectedOrder[key] = e.target.value;
    },
    addCTTW(sku) {
      const cctw = sku.split("-");
      return `${cctw[cctw.length - 1]} ct`;
    },
    async getAllOrders() {
      const orderRef = collection(db, "special_orders");
      const orderQuery = query(orderRef, orderBy("createdAt", "desc"));

      const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
        this.orders = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
        this.tableData = this.orders;
      });
    },
    async getAllClients() {
      const orderRef = collection(db, "clients");
      const orderQuery = query(orderRef, orderBy("company", "asc"));

      const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
        this.clients = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      });
    },
    async getAllSalesReps() {
      const orderRef = collection(db, "sales_rep");
      const orderQuery = query(orderRef, orderBy("firstName", "asc"));

      const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
        this.salesReps = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
      });
    },
    handlePagination(value) {
      this.pagination.currentPage = value;
    },

    searchForOrder(e) {
      if (!e.target.value) {
        this.tableData = this.order;
      }
      let input = e.target.value.toLowerCase();
      const validateOrder = (order) => {
        if (
          order.orderNumber.toLowerCase().includes(input) ||
          order.sku.toLowerCase().includes(input) ||
          order.purchaseOrderNumber.toLowerCase().includes(input) ||
          order.consumer.email.toLowerCase().includes(input) ||
          `${order.consumer.name} ${order.consumer.lastName}`
            .toLowerCase()
            .includes(input) ||
          // order.storeId.includes(input) ||
          order.client.company.toLowerCase().includes(input)
        ) {
          return true;
        } else return false;
      };
      this.tableData = this.orders.filter((order) => validateOrder(order));
    },

    resetTable() {
      this.pagination.currentPage = 1;
      this.searchedData = [];
      this.fetchData("start");
    },

    async fetchData(action) {
      if (this.searchedData.length) return false;
      if (action == "start") {
        try {
          const orderRef = collection(db, "special_orders");
          let orderQuery;
          if (this.isSuperAdmin || this.isEmployee) {
            orderQuery = query(
              orderRef,
              orderBy("createdAt", "desc"),
              endAt(0),
              limit(this.pagination.perPage)
            );
          } else {
            let queryfield;
            let queryCondition;
            let queryValue;
            if (this.isClient) {
              queryfield = "client.id";
              queryCondition = "==";
              queryValue = this.currentUser.id;
            } else if (this.isStore) {
              queryfield = "consumer.storeId";
              queryCondition = "==";
              queryValue = this.currentUser.id;
            } else if (this.isSalesRep) {
              queryfield = "salesRep.id";
              queryCondition = "==";
              queryValue = this.currentUser.id;
            }

            orderQuery = query(
              orderRef,
              where(queryfield, queryCondition, queryValue),
              orderBy("createdAt", "desc"),
              endAt(0),
              limit(this.pagination.perPage)
            );
          }

          const unsub = await onSnapshot(
            orderQuery,
            (querySnapshot) => {
              this.orders = querySnapshot.docs.map((doc) =>
                Object.assign({ id: doc.id }, doc.data())
              );
              this.tableData = this.orders;
            },
            (err) => {
              console.debug(err);
            }
          );
        } catch (error) {
          console.debug("Error fetching data:", error);
        }
      } else if (action == "next") {
        this.fetchNextData();
      } else if (action == "prev") {
        this.fetchPreviousData();
      }

      // else if (action == "per-page") {
      //   let firstDoc = this.tableData[0];
      //   firstDoc = await this.productsRef.doc(firstDoc.id).get();
      //   const querySnapshot = await this.productsRef
      //     .orderBy("createdAt", "desc") // Change to your sorting criteria
      //     .startAt(firstDoc)
      //     .limit(this.pagination.perPage)
      //     .get();

      //   this.tableData = querySnapshot.docs.map((doc) =>
      //     Object.assign({ id: doc.id }, doc.data())
      //   );
      // }
    },

    async fetchNextData() {
      const orderRef = collection(db, "special_orders");
      let lastDoc = this.tableData[this.tableData.length - 1];
      lastDoc = await getDoc(doc(orderRef, lastDoc.id));
      this.tableData = [];
      try {
        const orderRef = collection(db, "special_orders");
        let orderQuery;
        if (this.isSuperAdmin || this.isEmployee) {
          orderQuery = query(
            orderRef,
            orderBy("createdAt", "desc"),
            startAfter(lastDoc),
            limit(this.pagination.perPage)
          );
        } else {
          let queryfield;
          let queryCondition;
          let queryValue;
          if (this.isClient) {
            queryfield = "client.id";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          } else if (this.isStore) {
            queryfield = "consumer.storeId";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          } else if (this.isSalesRep) {
            queryfield = "salesRep.id";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          }

          orderQuery = query(
            orderRef,
            where(queryfield, queryCondition, queryValue),
            orderBy("createdAt", "desc"),
            startAfter(lastDoc),
            limit(this.pagination.perPage)
          );
        }

        const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
          this.orders = querySnapshot.docs
            .map((doc) => Object.assign({ id: doc.id }, doc.data()))
            .reverse();
          this.tableData = this.orders;
        });
      } catch (error) {
        console.debug("Error fetching data:", error);
      }
    },

    async fetchPreviousData() {
      const orderRef = collection(db, "special_orders");
      let firstDoc = this.tableData[0];
      firstDoc = await getDoc(doc(orderRef, firstDoc.id));
      this.tableData = [];
      try {
        let orderQuery;
        if (this.isSuperAdmin || this.isEmployee) {
          orderQuery = query(
            orderRef,
            orderBy("createdAt", "asc"),
            startAfter(firstDoc)
          );
        } else {
          let queryfield;
          let queryCondition;
          let queryValue;
          if (this.isClient) {
            queryfield = "client.id";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          } else if (this.isStore) {
            queryfield = "consumer.storeId";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          } else if (this.isSalesRep) {
            queryfield = "salesRep.id";
            queryCondition = "==";
            queryValue = this.currentUser.id;
          }

          orderQuery = query(
            orderRef,
            where(queryfield, queryCondition, queryValue),
            orderBy("createdAt", "asc"),
            limit(this.pagination.perPage)
          );
        }

        const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
          this.orders = querySnapshot.docs
            .map((doc) => Object.assign({ id: doc.id }, doc.data()))
            .reverse();
          this.tableData = this.orders;
        });
      } catch (error) {
        console.debug("Error fetching data:", error);
      }
    },
  },
};
</script>
<style scoped>
.hidden-containers {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

/* .table-row {
  cursor: pointer;
} */

.search-bar {
  position: relative;
  width: 535px !important;
  top: 10px;
  left: 10px;
  display: flex;
}

.pagination-wrapper {
  padding: 20px;
}

.color-key-container {
  width: 258px;
  position: relative;
}

.color-key-dropdown {
  position: absolute;
  width: 100%;
  top: 30px;
  left: 24px;
  z-index: 999;
  background-color: white;
}

.color-status {
  width: 100%;
  text-align: center;
}

.color-status:hover {
  transform: scale(1.2);
}

.act-icon:hover {
  transform: scale(1.3);
}

.images {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  border: 1px dashed black;
  padding: 10px;
}
</style>
