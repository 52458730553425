<template>
  <div class="upload-container">
    <h3>Upload Front and Back of the Check</h3>
    <div style="font-size: 12px; color: red; font-weight: bold;">
      Please make sure your photos are clear and readable and it should be a
      close up shot of your check. Must be on a white or clear background and
      the back of your check must be signed and you MUST write on the back
      <div style="font-style: italic; font-weight: bolder;">"FOR DEPOSIT ONLY"</div>
    </div>
    <div class="upload-section">
      <label for="frontCheck" class="file-label">Upload Front of Check:</label>
      <input
        type="file"
        id="frontCheck"
        @change="onFileChange('front', $event)"
        class="file-input"
      />
      <div v-if="frontCheckPreview" class="image-preview">
        <img :src="frontCheckPreview" alt="Front of Check Preview" />
      </div>
    </div>

    <div class="upload-section">
      <label for="backCheck" class="file-label">Upload Back of Check:</label>
      <input
        type="file"
        id="backCheck"
        @change="onFileChange('back', $event)"
        class="file-input"
      />
      <div v-if="backCheckPreview" class="image-preview">
        <img :src="backCheckPreview" alt="Back of Check Preview" />
      </div>
    </div>

    <button @click="encryptAndEmit" class="submit-button">Upload</button>
  </div>
</template>
  
  <script>
import CryptoJS from "crypto-js";

export default {
  props: {
    iv: {},
  },
  data() {
    return {
      frontCheckFile: null,
      backCheckFile: null,
      frontCheckPreview: null,
      backCheckPreview: null,
    };
  },
  methods: {
    async onFileChange(type, event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const base64Image = e.target.result.split(",")[1]; // Base64 without metadata

          if (type === "front") {
            this.frontCheckFile = base64Image;

            // Encrypt and decrypt immediately for preview
            const encryptedFront = await this.encryptImage(base64Image);
            const decryptedFront = this.decryptImage(
              encryptedFront.encryptedData,
              encryptedFront.key,
              encryptedFront.iv
            );

            // Display preview of the decrypted image
            this.frontCheckPreview = `data:image/png;base64,${decryptedFront}`;
          } else {
            this.backCheckFile = base64Image;

            const encryptedBack = await this.encryptImage(base64Image);
            const decryptedBack = this.decryptImage(
              encryptedBack.encryptedData,
              encryptedBack.key,
              encryptedBack.iv
            );

            this.backCheckPreview = `data:image/png;base64,${decryptedBack}`;
          }
        };
        reader.readAsDataURL(file); // Read file as data URL for Base64 conversion
      }
    },

    async encryptAndEmit() {
      if (!this.frontCheckFile || !this.backCheckFile) {
        this.$swal.fire("Please upload both FRONT and BACK of the check.");
        return;
      }

      try {
        const encryptedFront = await this.encryptImage(this.frontCheckFile);
        const encryptedBack = await this.encryptImage(this.backCheckFile);

        // Emit encrypted data to parent component
        this.$emit("encrypted-check-data", {
          front: encryptedFront,
          back: encryptedBack,
        });
      } catch (error) {
        console.error("Encryption error:", error);
      }
    },

    async encryptImage(base64Image) {
      // Convert Base64 string to WordArray
      const wordArray = CryptoJS.enc.Base64.parse(base64Image);

      // Generate key and IV
      const key = CryptoJS.lib.WordArray.random(32); // AES-256 key (32 bytes)
      const iv = CryptoJS.lib.WordArray.random(16); // IV (16 bytes)

      // Encrypt the image
      const encrypted = CryptoJS.AES.encrypt(wordArray, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Return encrypted data, key, and iv (in Base64)
      return {
        encryptedData: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
        key: key.toString(CryptoJS.enc.Base64),
        iv: iv.toString(CryptoJS.enc.Base64),
      };
    },

    decryptImage(encryptedData, key, iv) {
      // Parse Base64 encoded key and IV
      const keyBytes = CryptoJS.enc.Base64.parse(key);
      const ivBytes = CryptoJS.enc.Base64.parse(iv);

      // Decrypt the encrypted image
      const decrypted = CryptoJS.AES.decrypt(encryptedData, keyBytes, {
        iv: ivBytes,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Convert decrypted data to Base64 string
      return CryptoJS.enc.Base64.stringify(decrypted);
    },
  },
};
</script>
  
  <style scoped>
.upload-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

h3 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.upload-section {
  margin-bottom: 20px;
}

.file-label {
  font-weight: bold;
  color: #555;
  display: block;
  margin-bottom: 8px;
}

.file-input {
  width: 100%;
  padding: 8px;
  padding-left: 2rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black;
}

.image-preview {
  margin-top: 10px;
  text-align: center;
}

.image-preview img {
  max-width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4c74af;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  transform: scale(1.02);
}
</style>
  