<template>
  <div v-if="order.consumer" class="container-fluid">
    <div class="nav-wrapper position-relative end-0">
      <ul class="nav nav-pills nav-fill p-1" role="tablist">
        <li class="nav-item">
          <p
            @click="selectedTab = 'details'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'details' }"
          >
            General Details
          </p>
        </li>
        <!-- <li class="nav-item">
          <p
            @click="selectedTab = 'summary'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'summary' }"
          >
            Financial Summary
          </p>
        </li> -->

        <li class="nav-item">
          <p
            @click="selectedTab = 'notes'"
            class="nav-link mb-0 px-0 py-1"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0.5rem;
            "
            :class="{ active: selectedTab == 'notes' }"
          >
            Notes
            <soft-badge
              v-if="order.hasActiveNotes"
              className="order-detail-badge"
              color="success"
              >!</soft-badge
            >
          </p>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-4">
          <div v-if="selectedTab == 'details'" class="card-body p-3 pt-0">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="text-sm mb-0">
                  Order no.
                  <b>{{ order.orderNumber }}</b> from Store #
                  <b @click="goToStore">{{ order.consumer.storeId }}</b>
                </p>
                <p class="text-sm mb-0">
                  Created On.
                  <b>{{ format_date_time(order.createdAt) }}</b>
                </p>
                <p class="text-sm">
                  Reference #:
                  <b> {{ order.client.refNum }}</b>
                </p>
                <div style="width: 100%">
                  <div class="order-ids">
                    <p>
                      Collection: <b>{{ order.collection.title }} </b>
                    </p>
                    <p>
                      Date: <b> {{ format_date(order.createdAt) }}</b>
                    </p>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Item Description</th>
                        <th v-if="order.jewelryType != 'Earring'">Size</th>
                        <th>Carat Weight</th>
                        <th>Metal</th>
                        <th>Shape</th>
                        <th>Quality</th>
                        <th v-if="order.engravings || order.birthStones.length">
                          Customization
                        </th>
                        <th>Quantity</th>
                        <th style="color: red">
                          Metal Price/Rate <span>*</span>
                        </th>
                        <th style="color: red">Quoted Price <span>*</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{
                            `${order.origin} ${
                              order.jewelryType == "Ring"
                                ? `Size ${order.size}`
                                : ""
                            },  ${order.caratWeight}ct t.w ${order.shape} ${
                              order.metal.name
                            } ${order.style} ${order.jewelryType}`
                          }}
                        </td>
                        <td v-if="order.jewelryType != 'Earring'">
                          {{ order.size }}
                        </td>
                        <td>{{ `${order.caratWeight}ct t.w` }}</td>
                        <td>{{ order.metal.name }}</td>
                        <td>{{ order.shape }}</td>
                        <td>
                          <div>{{ order.origin }}</div>
                          <div>{{ order.quality }}</div>
                        </td>
                        <td v-if="order.engravings || order.birthStones.length">
                          <table>
                            <thead>
                              <tr>
                                <th>Engravings</th>
                                <th>Birthstones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td v-if="order.engravings">
                                  <div class="engravings">
                                    <!-- <p>Font: {{ order.engravings.font }}</p> -->
                                    <p
                                      :style="{
                                        'font-family': order.engravings.font,
                                        'font-style': 'italic',
                                        'text-align': 'center',
                                      }"
                                    >
                                      {{ order.engravings.text }}
                                    </p>
                                  </div>
                                </td>
                                <td v-if="order.birthStones.length">
                                  <div class="birthstones">
                                    <p
                                      v-for="stone in order.birthStones"
                                      :key="stone.name"
                                    >
                                      {{ stone.name }}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>{{ order.quantity }}</td>
                        <td>
                          <soft-input
                            style="width: 100%"
                            type="number"
                            label="Metal Price"
                            @input="
                              handleQuotedPrice(
                                $event,
                                'gold_metal_price_rates.price'
                              )
                            "
                            placeholder="99.99"
                            min="0"
                            :value="order.gold_metal_price_rates.price"
                          >
                          </soft-input>
                          <soft-input
                            style="width: 100%"
                            type="number"
                            :label="`${order.metal.gram} Metal Rate`"
                            placeholder="99.99"
                            :disabled="true"
                            min="0"
                            :value="
                              order.gold_metal_price_rates.metal_rates[
                                `price_gram_${order.metal.gram}`
                              ]
                            "
                          >
                          </soft-input>
                          <sub style="font-weight: bolder"
                            >Add Metal Rate / Price Here</sub
                          >
                        </td>
                        <td>
                          <soft-input
                            style="width: 100%"
                            type="number"
                            @input="handleQuotedPrice($event, 'quoutedPrice')"
                            placeholder="99.99"
                            min="0"
                            :value="order.quoutedPrice"
                          >
                          </soft-input>
                          <sub style="font-weight: bolder"
                            >Add Quoted Price Here</sub
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="description">
                  {{ order.description }}
                </div>
                <div class="client-uploaded-images" v-if="order.images.length">
                  <label for="">Client's Uploaded Reference Images </label>
                  <div class="images">
                    <img
                      v-for="image in order.images"
                      :key="image.url"
                      class="image"
                      :src="image.url"
                      alt=""
                      srcset=""
                      @click="enlargeImage(image)"
                    />
                  </div>
                </div>
                <div>
                  <label for="">UPLOAD C.A.D and Quoted Images </label>
                  <div style="width: 100%">
                    <Multiple
                      @handle-draggable="
                        handleMediaArrangement($event, order, 'factoryImages')
                      "
                      @uploaded-media="
                        handleImageUpload($event, 'factoryImages')
                      "
                      @remove-media="
                        handleImageRemoval($event, 'factoryImages', 'mult')
                      "
                      :files="order.factoryImages"
                      media_type="factoryImages"
                      :useCustomPath="true"
                      :path="`special_orders/${order.id}`"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedTab == 'notes'" class="card-body p-5 pt-0 row">
            <Notes collection="orders" :dataId="order.id"></Notes>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import ImageModal from "@/components/ImageModal.vue";
import Notes from "@/components/Notes.vue";
import Multiple from "@/components/Upload/Multiple.vue";

import {
  removeMediaFromStorage,
  deleteDocument,
  updateDocument,
} from "@/db/index";

export default {
  name: "OrderDetails",
  components: {
    SoftButton,
    SoftAvatar,
    SoftBadge,
    SoftInput,
    SoftCheckbox,
    ImageModal,
    Notes,
    Multiple,
  },
  props: {
    order: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  emits: ["handle-input"],
  data() {
    return {
      selectedTab: "details",
      showTrackingInputs: false,
      selectedImage: null,
      displayedImage: null,
      statuses: [
        "Order Created",
        "Factory Received Order",
        "In Process",
        "Shipped To DIS",
        "Delayed",
        "DIS Received Order",
        "QA Staging",
        "In Vault",
        "Shipped To Store",
        "Completed",
      ],
      metals: {
        wg: "White Gold",
        yg: "Yellow Gold",
        rg: "Rose Gold",
        plt: "Platinum",
      },
      shapeType: {
        earth: "Natural Stone",
        lab: "Lab Made",
      },

      notes: 0,
    };
  },
  watch: {
    order(e) {
      if (e) {
        const image = e.images.filter((i) => i.type.includes("image"));
        this.displayedImage = image[0] || null;
      }
    },
  },

  methods: {
    handleImageUpload(value, key) {
      if (!this.order.factoryImages) this.order.factoryImages = [];
      this.order.factoryImages = [].concat(
        ...this.order.factoryImages,
        ...value
      );
    },

    handleImageRemoval(value, key) {
      removeMediaFromStorage(value.ref);
      this.order[key] = this.order[key].filter((i) => i.ref !== value.ref);
    },
    // enlargeImage(img) {
    //   this.selectedImage = img.url;
    //   const modalElement = document.getElementById("image-modal");
    //   const bootstrapModal = new bootstrap.Modal(modalElement);
    //   bootstrapModal.show();
    // },
    handleQuotedPrice(e, key) {
      let value = parseFloat(e.target.value);
      if (key.includes(".")) {
        const keys = key.split(".");
        this.order[keys[0]][keys[1]] = value;
        this.handleMetalRate(value);
      } else {
        this.order[key] = value;
      }
    },
    addCTTW(order) {
      const cctw = order.sku.split("-");
      return `${cctw[cctw.length - 1]} ct`;
    },
    handleMetalRate(data) {
      const current_gold_exchange_metal_rates = {
        "14k": parseFloat(((data / 31.1) * 0.585 * 1.07).toFixed(2)),
        "18k": parseFloat(((data / 31.1) * 0.75 * 1.07).toFixed(2)),
      };

      this.order.gold_metal_price_rates.metal_rates[
        `price_gram_${this.order.metal.gram}`
      ] = current_gold_exchange_metal_rates[this.order.metal.gram];
    },
  },
};
</script>
<style scoped>
/* IDS */
#image-info {
  width: 40%;
  text-align: center;
  font-style: italic;
}
#delete {
  width: 100%;
  display: flex;
  align-content: flex-end;
  justify-content: end;
  margin-top: 5px;
}
#user {
  margin-left: 10px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#assigned-users {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 25px;
  overflow: auto;
}
#note-list {
  max-height: 300px;
  overflow: auto;
  margin-top: 20px;
  border: 2px solid rgba(27, 105, 111, 0.4);
}
#notes {
  margin-bottom: 25px;
  margin-top: 25px;
}
#note {
  width: 100%;
  background: rgba(73, 199, 213, 0.1);
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  max-height: 100px;
  overflow: auto;
}
#author {
  font-size: 12px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#date {
  font-size: 10px;
  font-weight: bold;
}
#user-dropdown {
  width: 300px;
  margin-top: -40px;
}
#note-section {
  min-height: 200px;
  max-height: 200px;
  padding: 10px 13px !important;
  font-size: 16px !important;
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  width: 100%;
  resize: none;
  background: rgba(73, 199, 213, 0.1);
}
#note-wrapper {
  width: 100%;
}

/* Classes */
.note-label {
  display: flex;
}
.shipping-info {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.birthstone {
  text-align: center;
}
.info-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.tabs {
  display: flex;
  width: 88%;
  flex-direction: row;
  flex-wrap: unset;
  justify-content: space-around;
  margin-bottom: 30px;
}
.form {
  display: flex;
}
.images {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  border: 1px dashed black;
  padding: 10px;
}
.form-group {
  width: 250px;
  margin-right: 50px;
}
.metal-color-box {
  width: 30px;
  /* background-color: red; */
  height: 10px;
}

.nav-item {
  cursor: pointer;
}

.nav-item:last-child {
  cursor: pointer;
  border-right: none;
}

.active {
  background-color: rgba(23, 129, 69, 0.955) !important;
  color: white !important;
}

.col-lg-3 {
  width: 100% !important;
}

.image {
  width: 150px;
  cursor: zoom-in;
}

/* .images {
  display: flex;
  width: 145px;
  justify-content: center;
} */

.order-ids {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  text-align: center;
}

td {
  font-size: 14px;
}

tfoot td {
  font-weight: bold;
}

footer {
  text-align: left;
}

footer p {
  margin: 5px 0;
  font-size: 14px;
}
</style>
