<template>
  <div v-if="order.consumer" class="container-fluid">
    <div class="nav-wrapper position-relative end-0">
      <ul class="nav nav-pills nav-fill p-1" role="tablist">
        <li class="nav-item">
          <p
            @click="selectedTab = 'details'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'details' }"
          >
            General Details
          </p>
        </li>

        <li class="nav-item">
          <p
            @click="selectedTab = 'notes'"
            class="nav-link mb-0 px-0 py-1"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0.5rem;
            "
            :class="{ active: selectedTab == 'notes' }"
          >
            Notes
            <soft-badge
              v-if="order.hasActiveNotes"
              className="order-detail-badge"
              color="success"
              >!</soft-badge
            >
          </p>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-4">
          <div v-if="selectedTab == 'details'" class="card-body p-3 pt-0">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="text-sm mb-0">
                  Order no.
                  <b>{{ order.orderNumber }}</b> from Store #
                  <b @click="goToStore">{{ order.consumer.storeId }}</b>
                </p>
                <p class="text-sm mb-0">
                  Created On.
                  <b>{{ format_date_time(order.createdAt) }}</b>
                </p>
                <p class="text-sm">
                  Reference #:
                  <b> {{ order.client.refNum }}</b>
                </p>
                <div style="width: 100%">
                  <div class="order-ids">
                    <p>
                      Collection: <b>{{ order.collection.title }} </b>
                    </p>

                    <p>
                      Date: <b> {{ format_date(order.createdAt) }}</b>
                    </p>

                    <div v-if="isSuperAdmin">
                      <div
                        style="
                          display: flex;
                          flex-direction: row;
                          gap: 5px;
                          align-items: center;
                        "
                      >
                        Metal Price:
                        <b style="color: red">{{
                          format_price(order.gold_metal_price_rates.price)
                        }}</b>
                        <div
                          @click="showHelper = !showHelper"
                          class="helper-icon"
                        >
                          ?
                        </div>
                      </div>
                      <div v-if="showHelper" class="helper">
                        This is the metal price that Trezza used to quote this
                        order.
                      </div>
                    </div>
                    <div v-if="isSuperAdmin">
                      Metal Rates:
                      <span style="color: red">
                        <b
                          >{{
                            order.gold_metal_price_rates.metal_rates[
                              `price_gram_${order.metal.gram}`
                            ]
                          }}
                          <sub>on {{ order.metal.gram }}</sub></b
                        >
                      </span>
                    </div>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>Order #</th>
                        <th>Item Description</th>
                        <th v-if="order.proposalAccepted">SKU</th>
                        <th v-if="order.jewelryType != 'Earring'">Size</th>
                        <th>Carat Weight</th>
                        <th>Metal</th>
                        <th>Shape</th>
                        <th>Quality</th>
                        <th v-if="order.engravings || order.birthStones.length">
                          Customization
                        </th>
                        <th>Quantity</th>
                        <th v-if="isSuperAdmin" style="color: red">
                          Trezza's Quoted Price
                        </th>
                        <th style="color: red">
                          <div>
                            {{
                              isClient
                                ? "Luna's Quote"
                                : "Approximate Luna's Price"
                            }}
                          </div>
                          <sub v-if="!isClient">(Based on Formula)</sub>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ order.orderNumber }}</td>
                        <td>
                          {{
                            `${order.origin} ${
                              order.jewelryType == "Ring"
                                ? `Size ${order.size}`
                                : ""
                            },  ${order.caratWeight}ct t.w ${order.shape} ${
                              order.metal.name
                            } ${order.style} ${order.jewelryType}`
                          }}
                        </td>
                        <td v-if="order.proposalAccepted && isSuperAdmin">
                          <soft-input
                            style="width: 100%"
                            @input="handleAddingSKU"
                            placeholder="RG00000"
                            min="0"
                            :value="order.sku"
                          >
                          </soft-input>
                          <sub style="font-weight: bolder; color: red"
                            >Please Enter SKU Here</sub
                          >
                        </td>
                        <td v-if="order.jewelryType != 'Earring'">
                          {{ order.size }}
                        </td>
                        <td>{{ `${order.caratWeight}ct t.w` }}</td>
                        <td>{{ order.metal.name }}</td>
                        <td>{{ order.shape }}</td>
                        <td>
                          <div>{{ order.origin }}</div>
                          <div>{{ order.quality }}</div>
                        </td>
                        <td v-if="order.engravings || order.birthStones.length">
                          <table>
                            <thead>
                              <tr>
                                <th>Engravings</th>
                                <th>Birthstones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td v-if="order.engravings">
                                  <div class="engravings">
                                    <!-- <p>Font: {{ order.engravings.font }}</p> -->
                                    <p
                                      :style="{
                                        'font-family': order.engravings.font,
                                        'font-style': 'italic',
                                        'text-align': 'center',
                                      }"
                                    >
                                      {{ order.engravings.text }}
                                    </p>
                                  </div>
                                </td>
                                <td v-if="order.birthStones.length">
                                  <div class="birthstones">
                                    <p
                                      v-for="stone in order.birthStones"
                                      :key="stone.name"
                                    >
                                      {{ stone.name }}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>{{ order.quantity }}</td>
                        <td v-if="isSuperAdmin" style="color: red">
                          {{
                            order.quoutedPrice
                              ? format_price(order.quoutedPrice)
                              : "TBD"
                          }}
                        </td>
                        <td v-if="isClient" style="color: red">
                          {{
                            order.approximateFinalRetailPrice
                              ? format_price(order.approximateFinalRetailPrice)
                              : "TBD"
                          }}
                        </td>
                        <td v-else>
                          <div v-if="order.status != 'Pending Quote'">
                            <soft-input
                              :value="order.approximateFinalRetailPrice"
                              @input="handleRetailPrice"
                              type="number"
                              :disabled="order.proposalAccepted"
                            >
                            </soft-input>
                            <sub style="color: red; font-weight: bolder"
                              >You are able to edit this</sub
                            >
                          </div>

                          <div style="color: red" v-else>TBD</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div v-if="order.description">
                  <label for="">Client's Special Order Description </label>
                  <p class="description">
                    {{ order.description }}
                  </p>
                </div>
                <div class="client-uploaded-images" v-if="order.images.length">
                  <label for="">Client's Uploaded Reference Images </label>
                  <div class="images">
                    <img
                      v-for="image in order.images"
                      :key="image.url"
                      class="image"
                      :src="image.url"
                      alt=""
                      srcset=""
                      @click="enlargeImage(image)"
                    />
                  </div>
                </div>
                <div v-if="isSuperAdmin">
                  <label for="">Uploaded CAD and Quoted References Images </label>
                  <div class="images" v-if="order.factoryImages">
                    <img
                      v-for="image in order.factoryImages"
                      :key="image.url"
                      class="image"
                      :src="image.url"
                      alt=""
                      srcset=""
                      @click="enlargeImage(image)"
                    />
                  </div>
                </div>

                <div
                  v-if="order.factoryImages && isSuperAdmin"
                  style="margin-top: 20px"
                >
                  <button
                    @click="downloadImages"
                    type="button"
                    class="btn btn-primary"
                  >
                    Download Images
                  </button>
                </div>
                <image-modal
                  :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
                  :url="selectedImage"
                  type="image"
                />
              </div>
            </div>
          </div>

          <div v-if="selectedTab == 'notes'" class="card-body p-5 pt-0 row">
            <Notes collection="orders" :dataId="order.id"></Notes>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import ImageModal from "@/components/ImageModal.vue";
import PriceBreakdown from "../components/PriceBreakdown.vue";
import Notes from "@/components/Notes.vue";
import { mapState } from "vuex";

export default {
  name: "OrderDetails",
  components: {
    SoftButton,
    SoftAvatar,
    SoftBadge,
    SoftInput,
    SoftCheckbox,
    ImageModal,
    PriceBreakdown,
    Notes,
  },
  props: {
    order: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  emits: ["handle-input"],
  data() {
    return {
      selectedTab: "details",
      showTrackingInputs: false,
      selectedImage: null,
      showHelper: false,
      displayedImage: null,

      metals: {
        wg: "White Gold",
        yg: "Yellow Gold",
        rg: "Rose Gold",
        plt: "Platinum",
      },
      shapeType: {
        earth: "Natural Stone",
        lab: "Lab Made",
      },

      notes: 0,
    };
  },
  computed: {
    ...mapState("authModule", {
      currentUser: (state) => {
        if (state.admin.id) {
          return state.admin;
        } else {
          return JSON.parse(window.localStorage.getItem("admin_data"));
        }
      },
      isSuperAdmin: (state) => {
        return state.isSuperAdmin;
      },
      isClient: (state) => {
        return state.isClient;
      },
      isStore: (state) => {
        return state.isStore;
      },
      isSalesRep: (state) => {
        return state.isSalesRep;
      },

      isEmployee: (state) => {
        return state.isEmployee;
      },
    }),
  },
  watch: {
    order(e) {
      if (e) {
        const image = e.images.filter((i) => i.type.includes("image"));
        this.displayedImage = image[0] || null;
      }
    },
  },

  methods: {
    handleAddingSKU(e) {
      let input = e.target.value.toUpperCase();

      this.$emit("handle-input", { target: { value: input } }, "sku");
    },
    handleRetailPrice(e) {
      const value = Number(e.target.value);
      if (!isNaN(value)) {
        const event = { target: { value } };
        this.$emit("handle-input", event, "approximateFinalRetailPrice");
      }
    },
    async downloadImages() {
      const images = this.order.images;
      for (const image of images) {
        try {
          // Fetch the image as a Blob
          const response = await fetch(image.url);
          if (!response.ok) throw new Error(`Failed to fetch ${image.url}`);
          const blob = await response.blob();

          // Create a download link
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = image.name; // Extract file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          // Revoke the URL object after use
          URL.revokeObjectURL(link.href);
        } catch (error) {
          console.debug("Error downloading image:", error);
          this.$swal.fire("Error downloading image:", error);
        }
      }
    },
    enlargeImage(img) {
      this.selectedImage = img.url;
      const modalElement = document.getElementById("image-modal");
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.show();
    },
    handleStatusSelect(e) {
      if (e.target.value.includes("Shipped")) {
        this.showTrackingInputs = true;
        this.$emit(
          "handle-input",
          { target: { value: true } },
          "shippingDetails",
          "shippedToConsumer"
        );

        this.$emit(
          "handle-input",
          { target: { value: "" } },
          "shippingDetails",
          "carrier"
        );
        this.$emit(
          "handle-input",
          { target: { value: "" } },
          "shippingDetails",
          "trackingNumber"
        );
      } else {
        this.showTrackingInputs = false;
        this.$emit(
          "handle-input",
          { target: { value: false } },
          "shippingDetails",
          "shippedToConsumer"
        );
      }
      this.$emit("handle-input", e, "status");
    },
    addCTTW(order) {
      const cctw = order.sku.split("-");
      return `${cctw[cctw.length - 1]} ct`;
    },
  },
};
</script>
<style scoped>
/* IDS */
#image-info {
  width: 40%;
  text-align: center;
  font-style: italic;
}
#delete {
  width: 100%;
  display: flex;
  align-content: flex-end;
  justify-content: end;
  margin-top: 5px;
}
#user {
  margin-left: 10px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#assigned-users {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 25px;
  overflow: auto;
}
#note-list {
  max-height: 300px;
  overflow: auto;
  margin-top: 20px;
  border: 2px solid rgba(27, 105, 111, 0.4);
}
#notes {
  margin-bottom: 25px;
  margin-top: 25px;
}
#note {
  width: 100%;
  background: rgba(73, 199, 213, 0.1);
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  max-height: 100px;
  overflow: auto;
}
#author {
  font-size: 12px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#date {
  font-size: 10px;
  font-weight: bold;
}
#user-dropdown {
  width: 300px;
  margin-top: -40px;
}
#note-section {
  min-height: 200px;
  max-height: 200px;
  padding: 10px 13px !important;
  font-size: 16px !important;
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  width: 100%;
  resize: none;
  background: rgba(73, 199, 213, 0.1);
}
#note-wrapper {
  width: 100%;
}

/* Classes */

.description {
  border: 1px dashed black;
}
.note-label {
  display: flex;
}
.shipping-info {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.birthstone {
  text-align: center;
}
.info-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.tabs {
  display: flex;
  width: 88%;
  flex-direction: row;
  flex-wrap: unset;
  justify-content: space-around;
  margin-bottom: 30px;
}
.form {
  display: flex;
}

.metal-color-box {
  width: 30px;
  /* background-color: red; */
  height: 10px;
}

.nav-item {
  cursor: pointer;
}

.nav-item:last-child {
  cursor: pointer;
  border-right: none;
}

.active {
  background-color: rgba(23, 129, 69, 0.955) !important;
  color: white !important;
}

.col-lg-3 {
  width: 100% !important;
}

.image {
  width: 20%;
  cursor: zoom-in;
}

.images {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  border: 1px dashed black;
  padding: 10px;
}

.helper-icon {
  width: 20px;
  text-align: center;
  border: 1px solid black;
  border-radius: 20px;
  background-color: #47bb47;
  color: white;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
}
.helper {
  position: absolute;
  width: 150px;
  background-color: #37b333;
  margin-left: 105px;
  text-align: initial;
  color: white;
  padding: 10px;
  font-size: 12px;
  z-index: 100;
}

.order-ids {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  text-align: center;
}

td {
  font-size: 14px;
}

tfoot td {
  font-weight: bold;
}

footer {
  text-align: left;
}

footer p {
  margin: 5px 0;
  font-size: 14px;
}
</style>
