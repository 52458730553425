<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <!-- <div>
        <soft-button color="success" variant="gradient">New order</soft-button>
      </div> -->
      <div class="d-flex">
        <!-- <div class="dropdown d-inline">
          <soft-button
            id="navbarDropdownMenuLink2"
            color="dark"
            variant="outline"
            class="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Filters</soft-button
          >
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            style
          >
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Order Received</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: In Process</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Completed</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Shipped</a
              >
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
            <li>
              <a
                class="dropdown-item border-radius-md text-danger"
                href="javascript:;"
                >Remove Filter</a
              >
            </li>
          </ul>
        </div> -->
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="dark"
          variant="outline"
          data-type="csv"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-archive-2"></i>
          </span>
          <span class="btn-inner--text">Export CSV</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                style="width: 100%"
                @input="searchForOrder"
                placeholder="Search By Order #, PO#, Client, Customer, Store"
              >
              </soft-input>
            </div>
            <div class="table-wrapper">
              <table id="order-list" class="table">
                <thead class="thead-light">
                  <tr>
                    <th>Controls</th>
                    <th>Date/Time</th>
                    <th>Order #</th>
                    <th>PO #</th>
                    <th>Sales Rep</th>
                    <th>Client</th>
                    <!-- <th>Store ID</th> -->
                    <th>Consumer</th>
                    <th>Item Type</th>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>Total Cost</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="order in queriedData"
                    :key="order.id"
                    class="table-row"
                  >
                    <td class="align-items-center">
                      <p class="text-xs font-weight-bold ms-2 mb-0">
                        <a
                          class="mx-3 pointer"
                          data-bs-original-title="Preview collection"
                          @click="viewOrderDetails(order)"
                        >
                          <i
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Print Order Confirmation"
                            style="color: black !important"
                            class="fas fa-eye text-secondary"
                          ></i>
                        </a>

                        <a
                          class="mx-3 pointer"
                          @click="printOrderConfirmation(order)"
                        >
                          <i
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Print Order Confirmation"
                            style="color: black !important"
                            class="fas fa-print text-secondary"
                          ></i>
                        </a>
                      </p>
                    </td>
                    <td class="font-weight-bold">
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ format_date_time(order.createdAt) }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.orderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.purchaseOrderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{
                            `${order.salesRep.firstName} ${order.salesRep.lastName}`
                          }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.client.company }}
                        </p>
                      </div>
                    </td>
                    <!-- <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.consumer.storeId }}
                        </p>
                      </div>
                    </td> -->
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <span>
                          {{
                            `${order.consumer.firstName} ${order.consumer.lastName}`
                          }}
                        </span>
                      </div>
                    </td>

                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.jewelryType }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.sku }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.quantity }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ format_price(order.totalCost) }}
                        </p>
                      </div>
                    </td>

                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.status }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="pagination-wrapper">
              
              <pagination
                :value="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :from="from"
                :to="to"
                @input="handlePagination"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    id="clientOrderModal"
    :showCloseBtn="false"
    @close="(showModal = false), (selectedOrder = {})"
    :styleFormat="{
      width: '1000px',
      height: '1000px',
      marginLeft: '-10.75rem',
    }"
  >
    <template v-slot:title>
      <h2>Order Details</h2>
      <span class="required">Click off screen to close</span>
    </template>
    <template v-slot:body>
      <div class="order-details-wrapper">
        <OrderDetails @handle-input="handleOrderInput" :order="selectedOrder" />
      </div>
    </template>
    <template v-if="showSaveChanges" v-slot:footer>
      <button @click="saveChanges" type="button" class="btn btn-primary">
        Save changes
      </button>
    </template>
  </modal>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import Pagination from "@/components/Pagination.vue";
import OrderDetails from "@/views/ecommerce/Orders/OrderDetails.vue";

import {
  collection,
  doc,
  db,
  getDocs,
  updateDoc,
  query,
  where,
  orderBy,
} from "@/config/firebaseInit.js";

export default {
  name: "order-table",
  components: {
    Modal,
    OrderDetails,
    SoftAvatar,
    SoftButton,
    SoftCheckbox,
    SoftInput,
    Pagination,
  },
  props: {
    rep: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  data() {
    return {
      selectedOrder: {},
      orders: [],
      tableData: [],
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
      currentSort: "name",
      currentSortOrder: "asc",
      filterBy: {
        client: "",
        status: "",
        customers: [],
        startDate: "",
        endDate: "",
      },
      showModal: false,
      showSaveChanges: false,
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;

      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  created() {
    this.getAllOrders();
  },
  methods: {
    async saveChanges() {
      const data = this.selectedOrder.shippingDetails;
      if (data.carrier && data.trackingNumber) {
        await updateDoc(doc(db, "orders", this.selectedOrder.id), {
          shippingDetails: data,
          status: "Shipped To Store",
        }).then(() => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `Order # ${this.selectedOrder.id} has been updated`,
            type: "success",
          }).then(() => {
            this.getAllOrders();
          });
        });
      } else {
        this.$swal({
          icon: "error",
          title: "Missing Information",
          text: "Must have both a tracking # and a carrier",
          type: "error",
        });
      }
    },
    openModal() {
      const modalElement = document.getElementById("clientOrderModal");
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.show();
    },
    handleOrderInput(e, key, subKey) {
      this.showSaveChanges = true;
      if (subKey) this.selectedOrder[key][subKey] = e.target.value;
      else this.selectedOrder[key] = e.target.value;
    },
    viewOrderDetails(order) {
      this.selectedOrder = order;
      setTimeout(() => {
        this.openModal();
      }, 500);
    },
    handlePagination(value) {
      this.pagination.currentPage = value;
    },
    searchForOrder(e) {
      const inputVal = e.target.value;
      if (!inputVal) {
        this.tableData = this.orders;
        return;
      }
      this.tableData = this.orders.filter(
        (u) => u.firstName.includes(inputVal) || u.email.includes(inputVal)
      );
    },
    async getAllOrders() {
      const orderRef = collection(db, "orders");
      const orderQuery = query(
        orderRef,
        where("salesRep.id", "==", this.rep.id),
        orderBy("createdAt", "desc")
      );
      const orders = await getDocs(orderQuery);
      this.orders = orders.docs.map((user) =>
        Object.assign({ id: user.id }, user.data())
      );
      this.tableData = this.orders;
    },
    // async getAllOrders() {
    //   const orders = await getDocs(collection(db, "orders"));
    //   this.orders = orders.docs.map((order) =>
    //     Object.assign({ id: order.id }, order.data())
    //   );
    //   this.tableData = this.orders;
    // },
  },
};
</script>
