<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <!-- <div>
        <soft-button color="success" variant="gradient">New order</soft-button>
      </div> -->
      <div class="d-flex">
        <!-- <div class="dropdown d-inline">
          <soft-button
            id="navbarDropdownMenuLink2"
            color="dark"
            variant="outline"
            class="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Filters</soft-button
          >
          <ul
            class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
            aria-labelledby="navbarDropdownMenuLink2"
            style
          >
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Order Received</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: In Process</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Completed</a
              >
            </li>
            <li>
              <a class="dropdown-item border-radius-md" href="javascript:;"
                >Status: Shipped</a
              >
            </li>
            <li>
              <hr class="horizontal dark my-2" />
            </li>
            <li>
              <a
                class="dropdown-item border-radius-md text-danger"
                href="javascript:;"
                >Remove Filter</a
              >
            </li>
          </ul>
        </div> -->
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="dark"
          variant="outline"
          data-type="csv"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-archive-2"></i>
          </span>
          <span class="btn-inner--text">Export CSV</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4 p-3 pt-0">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                style="width: 100%"
                @input="searchForOrder"
                placeholder="Search By Order #, PO#, Client, Customer, Store"
              >
              </soft-input>
            </div>
            <div class="light">
              <div class="filters">
                <div class="filter customer">
                  <p class="filter-label">Status</p>
                  <v-select
                    :deselectFromDropdown="true"
                    v-model="filterBy.status"
                    :options="[
                      'Order Created',
                      'Factory Received Order',
                      'In Process',
                      'Shipped To DIS',
                      'Delayed',
                      'DIS Received Order',
                      'QA Staging',
                      'In Vault',
                      'Shipped To Store',
                    ]"
                    :closeOnSelect="true"
                  >
                  </v-select>
                </div>

                <!-- <div class="filter">
                  <p>Start From:</p>
                  <md-datepicker
                    class="datepicker"
                    @input="validateDateRange('startDate')"
                    v-model="filterBy.startDate"
                  />
                </div>
                <div class="filter">
                  <p>End To:</p>
                  <md-datepicker
                    class="datepicker"
                    @input="validateDateRange('endDate')"
                    v-model="filterBy.endDate"
                  />
                </div> -->

                <div class="filter filter-btn">
                  <soft-button
                    type="button"
                    color="primary"
                    variant="gradient"
                    class="ms-auto mb-0 js-btn-next"
                    @click="handleFilter"
                  >
                    Filter
                  </soft-button>
                  <soft-button
                    type="button"
                    color="warning"
                    variant="gradient"
                    class="ms-auto mb-0 js-btn-next"
                    @click="clearFilters"
                  >
                    Clear
                  </soft-button>
                </div>
              </div>
            </div>
            <div class="table-wrapper">
              <table id="order-list" class="table">
                <thead class="thead-light">
                  <tr>
                    <th>Controls</th>
                    <th>Date/Time</th>
                    <th>Order #</th>
                    <th>PO #</th>
                    <th>Sales Rep</th>
                    <th>Client</th>
                    <!-- <th>Store ID</th> -->
                    <th>Consumer</th>
                    <th>Item Type</th>
                    <th>SKU</th>
                    <th>Quantity</th>
                    <th>Total Cost</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="order in queriedData"
                    :key="order.id"
                    class="table-row"
                  >
                    <td class="align-items-center">
                      <p class="text-xs font-weight-bold ms-2 mb-0">
                        <a
                          class="mx-3 pointer"
                          data-bs-original-title="Preview collection"
                          @click="viewOrderDetails(order)"
                        >
                          <i
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Print Order Confirmation"
                            style="color: black !important"
                            class="fas fa-eye text-secondary"
                          ></i>
                        </a>

                        <a
                          class="mx-3 pointer"
                          @click="printOrderConfirmation(order)"
                        >
                          <i
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Print Order Confirmation"
                            style="color: black !important"
                            class="fas fa-print text-secondary"
                          ></i>
                        </a>
                      </p>
                    </td>
                    <td class="font-weight-bold">
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ format_date_time(order.createdAt) }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.orderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.purchaseOrderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{
                            `${order.salesRep.firstName} ${order.salesRep.lastName}`
                          }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.client.company }}
                        </p>
                      </div>
                    </td>
                    <!-- <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.consumer.storeId }}
                        </p>
                      </div>
                    </td> -->
                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <span>
                          {{
                            `${order.consumer.firstName} ${order.consumer.lastName}`
                          }}
                        </span>
                      </div>
                    </td>

                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.jewelryType }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.sku }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.quantity }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ format_price(order.totalCost) }}
                        </p>
                      </div>
                    </td>

                    <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.status }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="pagination-wrapper">
              <div class="page-dropdown">
                <label for="orders">Per page: &nbsp; </label>
                <select v-model="pagination.perPage" name="orders">
                  <option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
              
              <pagination
                :value="pagination.currentPage"
                class="pagination-no-border pagination-success"
                :per-page="pagination.perPage"
                :total="total"
                :from="from"
                :to="to"
                @input="handlePagination"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    id="clientOrderModal"
    :showCloseBtn="false"
    @close="(showModal = false), (selectedOrder = {})"
    :styleFormat="{
      width: '1000px',
      height: '1000px',
      marginLeft: '-10.75rem',
    }"
  >
    <template v-slot:title>
      <h2>Order Details</h2>
      <span class="required">Click off screen to close</span>
    </template>
    <template v-slot:body>
      <div class="order-details-wrapper">
        <OrderDetails @handle-input="handleOrderInput" :order="selectedOrder" />
      </div>
    </template>
    <template v-if="showSaveChanges" v-slot:footer>
      <button @click="saveChanges" type="button" class="btn btn-primary">
        Save changes
      </button>
    </template>
  </modal>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import Pagination from "@/components/Pagination.vue";
import OrderDetails from "@/views/ecommerce/Orders/OrderDetails.vue";

import {
  collection,
  doc,
  db,
  orderBy,
  query,
  updateDoc,
  onSnapshot,
  where,
  endAt,
  limit,
  getDoc,
} from "@/config/firebaseInit.js";

export default {
  name: "order-table",
  components: {
    Modal,
    OrderDetails,
    SoftAvatar,
    SoftButton,
    SoftCheckbox,
    SoftInput,
    Pagination,
  },
  props: {
    client: {
      type: Object,
      default() {
        return Object;
      },
    },
  },
  data() {
    return {
      selectedOrder: {},
      orders: [],
      tableData: [],
      searchedData: [],
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
      currentSort: "name",
      currentSortOrder: "asc",
      filterBy: {
        status: "",
        customers: [],
        startDate: "",
        endDate: "",
      },
      showModal: false,
      showSaveChanges: false,
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  created() {
    this.fetchData("start");
  },
  methods: {
    async handleFilter() {
      let timeFilter = false;

      let ref = collection(db, "orders");

      if (
        !this.filterBy.status &&
        !this.filterBy.startDate &&
        !this.filterBy.endDate
      ) {
        this.resetTable();
        return false;
      }

      try {
        if (this.filterBy.startDate && this.filterBy.endDate) {
          timeFilter = true;
          let startDateObj =
            typeof this.filterBy.startDate == "object"
              ? DateTime.fromJSDate(this.filterBy.startDate)
              : DateTime.fromISO(this.filterBy.startDate);

          let endDateObj =
            typeof this.filterBy.endDate == "object"
              ? DateTime.fromJSDate(this.filterBy.endDate)
              : DateTime.fromISO(this.filterBy.endDate);

          let startDate = startDateObj
            .setZone("America/New_York")
            .startOf("day")
            .toMillis();

          let endDate = endDateObj
            .setZone("America/New_York")
            .endOf("day")
            .toMillis();

          ref = ref
            .orderBy("createdAt", "desc")
            .startAt(endDate)
            .endAt(startDate);
        }

        for (const key in this.filterBy) {
          if (Object.hasOwnProperty.call(this.filterBy, key)) {
            if (key != "startDate" && key != "endDate") {
              const element = this.filterBy[key];
              if (element) {
                if (key == "status") {
                  ref = query(
                    ref,
                    where("client.id", "==", this.client.id),
                    where("status", "==", element)
                  );
                }
              }
            }
          }
        }

        await onSnapshot(ref, (querySnapshot) => {
          if (!querySnapshot.docs.length) {
            return this.$swal("Empty Search Records");
          }
          this.searchedData = querySnapshot.docs.map((d) =>
            Object.assign({ id: d.id }, d.data())
          );
        });
      } catch (error) {
        console.debug(error);

        return this.$swal({
          icon: "error",
          title: "Internal Issue",
          text: "Oops there seems to be an issue please try again or contact support at info@mylunacollection.com",
          type: "error",
        });
      }
    },
    clearFilters() {
      for (const key in this.filterBy) {
        if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
        } else {
          this.filterBy[key] = "";
        }
      }
      this.searchVal = null;
      this.resetTable();
    },
    async saveChanges() {
      const data = this.selectedOrder.shippingDetails;
      if (data.carrier && data.trackingNumber) {
        await updateDoc(doc(db, "orders", this.selectedOrder.id), {
          shippingDetails: data,
          status: "Shipped To Store",
        }).then(() => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `Order # ${this.selectedOrder.id} has been updated`,
            type: "success",
          }).then(() => {
            this.getAllOrders();
          });
        });
      } else {
        this.$swal({
          icon: "error",
          title: "Missing Information",
          text: "Must have both a tracking # and a carrier",
          type: "error",
        });
      }
    },
    openModal() {
      const modalElement = document.getElementById("clientOrderModal");
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.show();
    },
    handleOrderInput(e, key, subKey) {
      this.showSaveChanges = true;
      if (subKey) this.selectedOrder[key][subKey] = e.target.value;
      else this.selectedOrder[key] = e.target.value;
    },
    viewOrderDetails(order) {
      this.selectedOrder = order;
      setTimeout(() => {
        this.openModal();
      }, 500);
    },
    handlePagination(value) {
      this.pagination.currentPage = value;
    },
    searchForOrder(e) {
      const inputVal = e.target.value;
      if (!inputVal) {
        this.tableData = this.orders;
        return;
      }
      this.tableData = this.orders.filter(
        (u) => u.firstName.includes(inputVal) || u.email.includes(inputVal)
      );
    },
    async getAllOrders() {
      const orderRef = collection(db, "orders");
      const orderQuery = query(
        orderRef,
        where("client.id", "==", this.client.id),
        orderBy("createdAt", "desc")
      );

      const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
        this.orders = querySnapshot.docs.map((doc) =>
          Object.assign({ id: doc.id }, doc.data())
        );
        this.tableData = this.orders;
      });
    },

    resetTable() {
      this.pagination.currentPage = 1;
      this.searchedData = [];
      this.fetchData("start");
    },

    async fetchData(action) {
      if (this.searchedData.length) return false;
      if (action == "start") {
        try {
          const orderRef = collection(db, "orders");
          const orderQuery = query(
            orderRef,
            where("client.id", "==", this.client.id),
            orderBy("createdAt", "desc"),
            endAt(0),
            limit(this.pagination.perPage)
          );

          const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
            this.orders = querySnapshot.docs.map((doc) =>
              Object.assign({ id: doc.id }, doc.data())
            );
            this.tableData = this.orders;
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (action == "next") {
        this.fetchNextData();
      } else if (action == "prev") {
        this.fetchPreviousData();
      }

      // else if (action == "per-page") {
      //   let firstDoc = this.tableData[0];
      //   firstDoc = await this.productsRef.doc(firstDoc.id).get();
      //   const querySnapshot = await this.productsRef
      //     .orderBy("createdAt", "desc") // Change to your sorting criteria
      //     .startAt(firstDoc)
      //     .limit(this.pagination.perPage)
      //     .get();

      //   this.tableData = querySnapshot.docs.map((doc) =>
      //     Object.assign({ id: doc.id }, doc.data())
      //   );
      // }
    },

    async fetchNextData() {
      const orderRef = collection(db, "orders");
      let lastDoc = this.tableData[this.tableData.length - 1];
      lastDoc = await getDoc(doc(orderRef, lastDoc.id));
      this.tableData = [];
      try {
        const orderQuery = query(
          orderRef,
          where("client.id", "==", this.client.id),
          orderBy("createdAt", "desc"),
          startAfter(lastDoc),
          limit(this.pagination.perPage)
        );

        const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
          this.orders = querySnapshot.docs.map((doc) =>
            Object.assign({ id: doc.id }, doc.data())
          );
          this.tableData = this.orders;
        });
      } catch (error) {
        console.debug("Error fetching data:", error);
      }
    },

    async fetchPreviousData() {
      const orderRef = collection(db, "orders");
      let firstDoc = this.tableData[0];
      firstDoc = await getDoc(doc(orderRef, firstDoc.id));
      this.tableData = [];
      try {
        const orderQuery = query(
          orderRef,
          where("client.id", "==", this.client.id),
          orderBy("createdAt", "asc"),
          startAfter(firstDoc),
          limit(this.pagination.perPage)
        );

        const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
          this.orders = querySnapshot.docs
            .map((doc) => Object.assign({ id: doc.id }, doc.data()))
            .reverse();
          this.tableData = this.orders;
        });
      } catch (error) {
        console.debug("Error fetching data:", error);
      }
    },
  },
};
</script>
