<template>
  <div v-if="order.consumer" class="container-fluid">
    <div class="nav-wrapper position-relative end-0">
      <ul class="nav nav-pills nav-fill p-1" role="tablist">
        <li class="nav-item">
          <p
            @click="selectedTab = 'details'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'details' }"
          >
            General Details
          </p>
        </li>
        <!-- <li class="nav-item">
          <p
            @click="selectedTab = 'summary'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'summary' }"
          >
            Financial Summary
          </p>
        </li> -->
        <li class="nav-item">
          <p
            @click="selectedTab = 'store'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'store' }"
          >
            Store's Details
          </p>
        </li>
        <li class="nav-item">
          <p
            @click="selectedTab = 'tracking'"
            class="nav-link mb-0 px-0 py-1"
            :class="{ active: selectedTab == 'tracking' }"
          >
            Tracking/Statuses
          </p>
        </li>
        <li class="nav-item">
          <p
            @click="selectedTab = 'notes'"
            class="nav-link mb-0 px-0 py-1"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0.5rem;
            "
            :class="{ active: selectedTab == 'notes' }"
          >
            Notes
            <soft-badge
              v-if="order.hasActiveNotes"
              className="order-detail-badge"
              color="success"
              >!</soft-badge
            >
          </p>
        </li>
      </ul>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card mb-4">
          <div v-if="selectedTab == 'details'" class="card-body p-3 pt-0">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <p class="text-sm mb-0">
                  Order no.
                  <b>{{ order.orderNumber }}</b> from Store #
                  <b @click="goToStore">{{ order.consumer.storeId }}</b>
                </p>
                <p class="text-sm mb-0">
                  Created On.
                  <b>{{ format_date_time(order.createdAt) }}</b>
                </p>
                <p class="text-sm">
                  Reference #:
                  <b> {{ order.client.refNum }}</b>
                </p>
                <section>
                  <div class="order-ids">
                    <p>
                      Collection: <b>{{ order.collection.title }} </b>
                    </p>
                    <p>
                      Purchase Order #: <b> {{ order.purchaseOrderNumber }}</b>
                    </p>
                    <p>
                      Order #: <b>{{ order.orderNumber }} </b>
                    </p>
                    <p>
                      Date: <b> {{ format_date(order.createdAt) }}</b>
                    </p>
                  </div>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div>Thumbnail</div>
                          <sub>Click the image to view</sub>
                        </th>
                        <th>Order #</th>
                        <th>Item(s) Ordered</th>
                        <th>SKU</th>
                        <th v-if="order.jewelryType != 'Earring'">Size</th>
                        <th>Carat Weight</th>
                        <th>Metal</th>
                        <th>Shape</th>
                        <th>Quality</th>
                        <th v-if="order.engravings || order.birthStones.length">
                          Customization
                        </th>
                        <th>Quantity</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="images">
                            <div v-if="displayedImage">
                              <img
                                @click="enlargeImage(displayedImage)"
                                v-if="displayedImage.url"
                                class="image"
                                :src="displayedImage.url"
                                alt=""
                                srcset=""
                              />
                            </div>
                          </div>
                        </td>
                        <td>{{ order.orderNumber }}</td>
                        <td>
                          {{
                            `${order.origin} ${
                              order.jewelryType == "Ring"
                                ? `Size ${order.size}`
                                : ""
                            },  ${order.caratWeight}ct t.w ${order.shape} ${
                              order.metal.name
                            } ${order.style} ${order.jewelryType}`
                          }}
                        </td>
                        <td>{{ order.sku }}</td>
                        <td v-if="order.jewelryType != 'Earring'">
                          {{ order.size }}
                        </td>
                        <td>{{ `${order.caratWeight}ct t.w` }}</td>
                        <td>{{ order.metal.name }}</td>
                        <td>{{ order.shape }}</td>
                        <td>
                          <div>{{ order.origin }}</div>
                          <div>{{ order.quality }}</div>
                        </td>
                        <td v-if="order.engravings || order.birthStones.length">
                          <table>
                            <thead>
                              <tr>
                                <th>Engravings</th>
                                <th>Birthstones</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td v-if="order.engravings">
                                  <div class="engravings">
                                    <!-- <p>Font: {{ order.engravings.font }}</p> -->
                                    <p
                                      :style="{
                                        'font-family': order.engravings.font,
                                        'font-style': 'italic',
                                        'text-align': 'center',
                                      }"
                                    >
                                      {{ order.engravings.text }}
                                    </p>
                                  </div>
                                </td>
                                <td v-if="order.birthStones.length">
                                  <div class="birthstones">
                                    <p
                                      v-for="stone in order.birthStones"
                                      :key="stone.name"
                                    >
                                      {{ stone.name }}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td>{{ order.quantity }}</td>
                        <td>
                          {{
                            format_price(order.priceBreakdown.storeTotalCost)
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>

                <image-modal
                  :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
                  :url="selectedImage"
                  type="image"
                />
              </div>
            </div>
          </div>
          <!-- <div
            v-if="selectedTab == 'summary'"
            class="card-body p-3 pt-0 row info-wrapper"
          >
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="info-container">
              <h6 class="mb-3 mt-4" style="text-decoration: underline">
                Order Number: {{ order.orderNumber }}
              </h6>
              <PriceBreakdown :order="order" />
            </div>
          </div> -->
          <div
            v-if="selectedTab == 'store'"
            class="card-body p-3 pt-0 row info-wrapper"
          >
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="info-container">
              <div class="info">
                <h6 class="mb-3 mt-4">Billing Information</h6>
                <ul class="list-group">
                  <li
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <h6 class="mb-3 text-sm">
                        {{ `${order.client.company} ` }}
                      </h6>
                      <!-- <span class="mb-2 text-xs">
                      Store Name:
                      <span class="text-dark font-weight-bold ms-2"
                        >Viking Burrito</span
                      >
                    </span> -->
                      <span class="mb-2 text-xs">
                        <label for="">Email Address:</label>
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.email }}</span
                        >
                      </span>
                      <span class="mb-2 text-xs">
                        <label for="">Phone #:</label>
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.phone }}</span
                        >
                      </span>
                      <div class="mb-2 text-xs">
                        <label for=""> Address:</label>
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.billing.addressOne }},</span
                        >
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.billing.city }},</span
                        >
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.billing.state }},</span
                        >
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.billing.zipCode }}</span
                        >
                      </div>
                      <!-- <span class="text-xs">
                        VAT Number:
                        <span class="text-dark ms-2 font-weight-bold"
                          >FRB1235476</span
                        >
                      </span> -->
                    </div>
                  </li>
                </ul>
              </div>

              <div class="info">
                <h6 class="mb-3 mt-4">Shipping Information</h6>
                <ul class="list-group">
                  <li
                    class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                  >
                    <div class="d-flex flex-column">
                      <h6 class="mb-3 text-sm">
                        {{ `${order.client.company} ` }}
                      </h6>
                      <!-- <span class="mb-2 text-xs">
                      Store Name:
                      <span class="text-dark font-weight-bold ms-2"
                        >Viking Burrito</span
                      >
                    </span> -->
                      <span class="mb-2 text-xs">
                        <label for="">Email Address:</label>
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.email }}</span
                        >
                      </span>
                      <span class="mb-2 text-xs">
                        <label for="">Phone #:</label>
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.phone }}</span
                        >
                      </span>
                      <div class="mb-2 text-xs">
                        <label for=""> Address:</label>
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.billing.addressOne }},</span
                        >
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.billing.city }},</span
                        >
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.billing.state }},</span
                        >
                        <span class="text-dark ms-2 font-weight-bold">
                          {{ order.consumer.billing.zipCode }}</span
                        >
                      </div>
                      <!-- <span class="text-xs">
                        VAT Number:
                        <span class="text-dark ms-2 font-weight-bold"
                          >FRB1235476</span
                        >
                      </span> -->
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div v-if="selectedTab == 'tracking'" class="card-body p-3 pt-0 row">
            <hr class="horizontal dark mt-0 mb-4" />
            <div class="col-lg-3 col-md-6 col-12">
              <h6 class="mb-3">Track order</h6>
              <div class="form">
                <div class="form-group">
                  <label for="">Order Status</label>
                  <select
                    :disabled="
                      (!$store.state.authModule.isSuperAdmin &&
                        !$store.state.authModule.isEmployee) ||
                      order.status === 'Completed'
                    "
                    id="choices-category-edit"
                    v-model="order.status"
                    class="form-control"
                    name="choices-category"
                    @change="handleStatusSelect"
                  >
                    <option
                      v-for="status in statuses"
                      :key="status"
                      :disabled="
                        [
                          'Order Created',
                          'Factory Received Order',
                          'In Process',
                          'Shipped To DIS',
                        ].includes(status)
                      "
                      :value="status"
                    >
                      {{ status }}
                    </option>
                  </select>
                </div>
                <div
                  class="shipping-info"
                  v-if="order.status.includes('Shipped')"
                >
                  <div class="form-group">
                    <label for=""
                      >Shipping Carrier <span class="required">*</span></label
                    >
                    <select
                      id="choices-category-edit"
                      v-model="order.shippingDetails.carrier"
                      class="form-control"
                      name="choices-category"
                      @change="
                        $emit(
                          'handle-input',
                          $event,
                          'shippingDetails',
                          'carrier'
                        )
                      "
                    >
                      <option value="UPS">UPS</option>
                      <option value="Fedex">Fedex</option>
                      <option value="Sequel">Sequel</option>
                      <option value="Loomis">Loomis International</option>
                    </select>
                  </div>
                  <div
                    v-if="order.shippingDetails.carrier != 'Loomis'"
                    class="form-group"
                  >
                    <label for=""
                      >Set Tracking Number
                      <span class="required">*</span></label
                    >
                    <SoftInput
                      @change="
                        $emit(
                          'handle-input',
                          $event,
                          'shippingDetails',
                          'trackingNumber'
                        )
                      "
                      :value="order.shippingDetails.trackingNumber"
                      type="text"
                      placeholder="Set tracking #"
                    />
                  </div>
                </div>
              </div>

              <div
                class="timeline timeline-one-side"
                style="
                  display: flex;
                  flex-direction: row;
                  max-width: 100%;
                  overflow-x: auto;
                  justify-content: flex-start;
                "
              >
                <div
                  v-for="(_status, index) in order.statusHistory"
                  :key="index"
                  class="timeline-block mb-3"
                >
                  <span class="timeline-step">
                    <i class="ni ni-bell-55 text-secondary"></i>
                  </span>
                  <div class="timeline-content">
                    <p class="text-dark text-sm font-weight-bold mb-0">
                      {{ _status.message }}
                    </p>
                    <p
                      class="text-secondary font-weight-bold text-xs mt-1 mb-0"
                    >
                      {{ format_date_time(_status.date) }}
                    </p>
                  </div>
                  <div class="divider"></div>
                </div>
                <!-- <div class="timeline-block mb-3">
                    <span class="timeline-step">
                      <i class="ni ni-html5 text-secondary"></i>
                    </span>
                    <div class="timeline-content">
                      <h6 class="text-dark text-sm font-weight-bold mb-0">
                        Generate order id #1832412
                      </h6>
                      <p
                        class="text-secondary font-weight-bold text-xs mt-1 mb-0"
                      >
                        22 DEC 7:21 AM
                      </p>
                    </div>
                  </div>
                  <div class="timeline-block mb-3">
                    <span class="timeline-step">
                      <i class="ni ni-cart text-secondary"></i>
                    </span>
                    <div class="timeline-content">
                      <h6 class="text-dark text-sm font-weight-bold mb-0">
                        Order transmitted to factory
                      </h6>
                      <p
                        class="text-secondary font-weight-bold text-xs mt-1 mb-0"
                      >
                        22 DEC 8:10 AM
                      </p>
                    </div>
                  </div>
                  <div class="timeline-block mb-3">
                    <span class="timeline-step">
                      <i
                        class="ni ni-check-bold text-success text-gradient"
                      ></i>
                    </span>
                    <div class="timeline-content">
                      <h6 class="text-dark text-sm font-weight-bold mb-0">
                        Certificate Created
                      </h6>
                      <p
                        class="text-secondary font-weight-bold text-xs mt-1 mb-0"
                      >
                        22 DEC 4:54 PM
                      </p>
                    </div>
                  </div> -->
              </div>
            </div>
          </div>

          <div v-if="selectedTab == 'notes'" class="card-body p-5 pt-0 row">
            <Notes collection="orders" :dataId="order.id"></Notes>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import ImageModal from "@/components/ImageModal.vue";
import PriceBreakdown from "../components/PriceBreakdown.vue";
import Notes from "@/components/Notes.vue";

export default {
  name: "OrderDetails",
  components: {
    SoftButton,
    SoftAvatar,
    SoftBadge,
    SoftInput,
    SoftCheckbox,
    ImageModal,
    PriceBreakdown,
    Notes,
  },
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ["handle-input"],
  data() {
    return {
      selectedTab: "details",
      showTrackingInputs: false,
      selectedImage: null,
      displayedImage: null,
      statuses: [
        "Order Created",
        "Factory Received Order",
        "In Process",
        "Shipped To DIS",
        "Delayed",
        "DIS Received Order",
        "QA Staging",
        "In Vault",
        "Shipped To Store",
        "Completed",
      ],
      metals: {
        wg: "White Gold",
        yg: "Yellow Gold",
        rg: "Rose Gold",
        plt: "Platinum",
      },
      shapeType: {
        earth: "Natural Stone",
        lab: "Lab Made",
      },

      notes: 0,
    };
  },
  created() {
    const image = this.order.images.filter((i) => i.type.includes("image"));
    this.displayedImage = image[0] || null;
  },
  methods: {
    async goToStore() {},
    enlargeImage(img) {
      this.selectedImage = img.url;
      const modalElement = document.getElementById("image-modal");
      const bootstrapModal = new bootstrap.Modal(modalElement);
      bootstrapModal.show();
    },
    handleStatusSelect(e) {
      if (e.target.value.includes("Shipped")) {
        this.showTrackingInputs = true;
        this.$emit(
          "handle-input",
          { target: { value: true } },
          "shippingDetails",
          "shippedToConsumer"
        );

        this.$emit(
          "handle-input",
          { target: { value: "" } },
          "shippingDetails",
          "carrier"
        );
        this.$emit(
          "handle-input",
          { target: { value: "" } },
          "shippingDetails",
          "trackingNumber"
        );
      } else {
        this.showTrackingInputs = false;
        this.$emit(
          "handle-input",
          { target: { value: false } },
          "shippingDetails",
          "shippedToConsumer"
        );
      }
      this.$emit("handle-input", e, "status");
    },
    addCTTW(order) {
      const cctw = order.sku.split("-");
      return `${cctw[cctw.length - 1]} ct`;
    },
  },
};
</script>
<style scoped>
/* IDS */
#image-info {
  width: 40%;
  text-align: center;
  font-style: italic;
}
#delete {
  width: 100%;
  display: flex;
  align-content: flex-end;
  justify-content: end;
  margin-top: 5px;
}
#user {
  margin-left: 10px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#assigned-users {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 25px;
  overflow: auto;
}
#note-list {
  max-height: 300px;
  overflow: auto;
  margin-top: 20px;
  border: 2px solid rgba(27, 105, 111, 0.4);
}
#notes {
  margin-bottom: 25px;
  margin-top: 25px;
}
#note {
  width: 100%;
  background: rgba(73, 199, 213, 0.1);
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  max-height: 100px;
  overflow: auto;
}
#author {
  font-size: 12px;
  color: rgb(28, 170, 186);
  font-weight: bold;
}
#date {
  font-size: 10px;
  font-weight: bold;
}
#user-dropdown {
  width: 300px;
  margin-top: -40px;
}
#note-section {
  min-height: 200px;
  max-height: 200px;
  padding: 10px 13px !important;
  font-size: 16px !important;
  border: 2px solid rgba(27, 105, 111, 0.4);
  margin-bottom: 0;
  width: 100%;
  resize: none;
  background: rgba(73, 199, 213, 0.1);
}
#note-wrapper {
  width: 100%;
}

/* Classes */
.note-label {
  display: flex;
}
.shipping-info {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.birthstone {
  text-align: center;
}
.info-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.tabs {
  display: flex;
  width: 88%;
  flex-direction: row;
  flex-wrap: unset;
  justify-content: space-around;
  margin-bottom: 30px;
}
.form {
  display: flex;
}
.form-group {
  width: 250px;
  margin-right: 50px;
}
.metal-color-box {
  width: 30px;
  /* background-color: red; */
  height: 10px;
}

.nav-item {
  cursor: pointer;
}

.nav-item:last-child {
  cursor: pointer;
  border-right: none;
}

.active {
  background-color: rgba(23, 129, 69, 0.955) !important;
  color: white !important;
}

.col-lg-3 {
  width: 100% !important;
}

.image {
  width: 150px;
  cursor: zoom-in;
}

.images {
  display: flex;
  width: 145px;
  justify-content: center;
}

.order-ids {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  text-align: center;
}

td {
  font-size: 14px;
}

tfoot td {
  font-weight: bold;
}

footer {
  text-align: left;
}

footer p {
  margin: 5px 0;
  font-size: 14px;
}
</style>
